/*
 * @Author: mjzhu
 * @describe: 
 * @Date: 2023-10-24 10:45:47
 * @LastEditTime: 2023-11-29 10:10:18
 * @FilePath: \awx-ui\src\components\workflow-design\flow-design.js
 */
export class DesignerStepComponents {

  constructor(
    designer
  ) {
    this.designer = designer
  }
  // 更新选中的step的内容
  updateStepsPropertiesByStepId (properties) {
    const step = this.getStep()
    const stepID = this.getStepId()
    step.properties = {...properties}
    this.designer.api.editor.createStepEditorContext(stepID).notifyPropertiesChanged()
  }
  updateStepsNameByStepId (name) {
    const step = this.getStep()
    const stepID = this.getStepId()
    step.name = name
    this.designer.api.editor.createStepEditorContext(stepID).notifyNameChanged()
  }
  updateStepsChildrenByStepId (name, properties, oldName) {
    const step = this.getStep()
    const stepID = this.getStepId()
    // 名称修改
    step.branches[`${name}`] = oldName ? step.branches[`${oldName}`] : []
    if (oldName)  delete step.branches[`${oldName}`]
    step.properties = {...properties}
    this.designer.api.editor.createStepEditorContext(stepID).notifyChildrenChanged()
  }
  // 更新整个节点
  updateStepSelfChangedByStepId(stepBack) {
    const step = this.getStep()
    const stepID = this.getStepId()
    step.name = stepBack.name
    step.icon = stepBack.icon
    step.properties = {...stepBack.properties}
    this.designer.api.editor.createStepEditorContext(stepID).notifyStepSelfChanged()
  }
  deleteStepsChlidren(name, properties) {
    const step = this.getStep()
    const stepID = this.getStepId()
    // 名称修改
    step.branches[`${name}`] = []
    delete step.branches[`${name}`]
    step.properties = {...properties}
    this.designer.api.editor.createStepEditorContext(stepID).notifyChildrenChanged()
  }
  getStep () {
    return this.designer.view.workspace.selectedStepComponent.step
  }
  getStepId () {
    return this.designer.view.workspace.selectedStepComponent.step.id
  }
}