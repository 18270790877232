import { Icon, getIcon, loadIcon, buildIcon } from "@iconify/vue2";

let counter = 0;
const regex = /\sid="(\S+)"/g;
const randomPrefix = "IconifyId" + Date.now().toString(16) + (Math.random() * 16777216 | 0).toString(16);
const defaultIconDimensions = Object.freeze({
  left: 0,
  top: 0,
  width: 16,
  height: 16,
});
const defaultIconTransformations = Object.freeze({
  rotate: 0,
  vFlip: false,
  hFlip: false,
});
const defaultIconProps = Object.freeze({
  ...defaultIconDimensions,
  ...defaultIconTransformations,
});
const defaultExtendedIconCustomisations = {
  ...defaultIconCustomisations,
  inline: false,
};
const defaultIconSizeCustomisations = Object.freeze({
  width: null,
  height: null
});
const defaultIconCustomisations = Object.freeze({
  ...defaultIconSizeCustomisations,
  ...defaultIconTransformations
});
const svgDefaults = {
  'xmlns': 'http://www.w3.org/2000/svg',
  'xmlns:xlink': 'http://www.w3.org/1999/xlink',
  'aria-hidden': true,
  'role': 'img',
};
const unitsSplit = /(-?[0-9.]*[0-9]+[0-9.]*)/g;
const unitsTest = /^-?[0-9.]*[0-9]+[0-9.]*$/g;
const isUnsetKeyword = (value) => value === "unset" || value === "undefined" || value === "none";

function calculateSize(size, ratio, precision) {
  if (ratio === 1) {
    return size;
  }
  precision = precision || 100;
  if (typeof size === "number") {
    return Math.ceil(size * ratio * precision) / precision;
  }
  if (typeof size !== "string") {
    return size;
  }
  const oldParts = size.split(unitsSplit);
  if (oldParts === null || !oldParts.length) {
    return size;
  }
  const newParts = [];
  let code = oldParts.shift();
  let isNumber = unitsTest.test(code);
  while (true) {
    if (isNumber) {
      const num = parseFloat(code);
      if (isNaN(num)) {
        newParts.push(code);
      } else {
        newParts.push(Math.ceil(num * ratio * precision) / precision);
      }
    } else {
      newParts.push(code);
    }
    code = oldParts.shift();
    if (code === void 0) {
      return newParts.join("");
    }
    isNumber = !isNumber;
  }
}

function replaceIDs(body, prefix = randomPrefix) {
  const ids = [];
  let match;
  while (match = regex.exec(body)) {
    ids.push(match[1]);
  }
  if (!ids.length) {
    return body;
  }
  const suffix = "suffix" + (Math.random() * 16777216 | Date.now()).toString(16);
  ids.forEach((id) => {
    const newID = typeof prefix === "function" ? prefix(id) : prefix + (counter++).toString();
    const escapedID = id.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    body = body.replace(
      new RegExp('([#;"])(' + escapedID + ')([")]|\\.[a-z])', "g"),
      "$1" + newID + suffix + "$3"
    );
  });
  body = body.replace(new RegExp(suffix, "g"), "");
  return body;
}

function mergeCustomisations(defaults, item) {
  const result = {
    ...defaults
  };
  for (const key in item) {
    const value = item[key];
    const valueType = typeof value;
    if (key in defaultIconSizeCustomisations) {
      if (value === null || value && (valueType === "string" || valueType === "number")) {
        result[key] = value;
      }
    } else if (valueType === typeof result[key]) {
      result[key] = key === "rotate" ? value % 4 : value;
    }
  }
  return result;
}
function IconToSvg (icon) {
  
  const props = {
    icon: getIcon(icon)
  }
  const customisations = mergeCustomisations(
    defaultExtendedIconCustomisations,
    props
  );
  const componentProps = { ...svgDefaults };
  // Style in Vue 2 components is always passed to rendered component, so no point in parsing it
  const style = {};
  // Get element properties
  for (let key in props) {
    const value = props[key];
    if (value === void 0) {
      continue;
    }
    switch (key) {
      // Properties to ignore
      case "icon":
      case "style":
      case "onLoad":
        break;
      // Boolean attributes
      case "inline":
      case "hFlip":
      case "vFlip":
        customisations[key] =
          value === true || value === "true" || value === 1;
        break;
      // Flip as string: 'horizontal,vertical'
      case "flip":
        if (typeof value === "string") {
          flipFromString(customisations, value);
        }
        break;
      // Color: override style
      case "color":
        style.color = value;
        break;
      // Rotation as string
      case "rotate":
        if (typeof value === "string") {
          customisations[key] = rotateFromString(value);
        } else if (typeof value === "number") {
          customisations[key] = value;
        }
        break;
      // Remove aria-hidden
      case "ariaHidden":
      case "aria-hidden":
        // Vue transforms 'aria-hidden' property to 'ariaHidden'
        if (value !== true && value !== "true") {
          delete componentProps["aria-hidden"];
        }
        break;
      default:
        const alias = customisationAliases[key];
        if (alias) {
          // Aliases for boolean customisations
          if (value === true || value === "true" || value === 1) {
            customisations[alias] = true;
          }
        } else if (defaultExtendedIconCustomisations[key] === void 0) {
          // Copy missing property if it does not exist in customisations
          componentProps[key] = value;
        }
    }
  }
  const item = buildIcon(getIcon(icon), customisations);
  console.log(item, 'ssdadddddd')
  return item
}
function getSvgPath (str) {
  let g = [];
  let rect = [];
  let path = [];
  
  // 解析 <g> 元素
  let gMatches = str.match(/<g(.*?)>/);
  if (gMatches) {
    let gAttributes = gMatches[1].match(/\b(\w+)\s*=\s*\"([^\"]*)\"/g);
    if (gAttributes) {
      let gObj = {};
      gAttributes.forEach(attr => {
        let [key, value] = attr.split(/\s*=\s*/);
        key = key.trim().replace(/\"/g, ''); // 修正属性名
        value = value.replace(/\"/g, '');
        gObj[key] = value;
      });
      g.push(gObj);
    }
  }
  
  // 解析 <rect> 元素
  let rectMatches = str.match(/<rect(.*?)\/>/g);
  if (rectMatches) {
    rectMatches.forEach(match => {
      let rectAttributes = match.match(/\b(\w+-?\w*)\s*=\s*\"([^\"]*)\"/g); // 修正正则表达式
      if (rectAttributes) {
        let rectObj = {};
        rectAttributes.forEach(attr => {
          let [key, value] = attr.split(/\s*=\s*/);
          key = key.trim().replace(/\"/g, ''); // 修正属性名
          value = value.replace(/\"/g, '');
          rectObj[key] = value;
        });
        rect.push(rectObj);
      }
    });
  }
  
  // 解析 <path> 元素
  let pathMatches = str.match(/<path(.*?)\/>/g);
  if (pathMatches) {
    pathMatches.forEach(match => {
      let pathAttributes = match.match(/\b(\w+-?\w*)\s*=\s*\"([^\"]*)\"/g); // 修正正则表达式
      if (pathAttributes) {
        let pathObj = {};
        pathAttributes.forEach(attr => {
          let [key, value] = attr.split(/\s*=\s*/);
          key = key.trim().replace(/\"/g, ''); // 修正属性名
          value = value.replace(/\"/g, '');
          pathObj[key] = value;
        });
        path.push(pathObj);
      }
    });
  }
  let defsArray = [];

  // 解析 <defs> 元素
  let defsMatches = str.match(/<defs>([\s\S]*?)<\/defs>/);
  if (defsMatches) {
    let defsContent = defsMatches[1];
    let linearGradientMatches = defsContent.match(/<linearGradient(.*?)<\/linearGradient>/g);
    if (linearGradientMatches) {
      linearGradientMatches.forEach(match => {
        let linearGradientObj = {};
        linearGradientObj.children = [];
  
        // 解析 <linearGradient> 元素的属性
        let linearGradientAttributes = match.match(/\b(\w+-?\w*)\s*=\s*\"([^\"]*)\"/g);
        if (linearGradientAttributes) {
          linearGradientObj.label = 'linearGradient';
          linearGradientAttributes.forEach(attr => {
            let [key, value] = attr.split(/\s*=\s*/);
            key = key.trim().replace(/\"/g, ''); // 修正属性名
            value = value.replace(/\"/g, '');
            if (key === 'id') {
              linearGradientObj.id = value;
            } else {
              linearGradientObj[key] = value;
            }
          });
        }
  
        // 解析 <stop> 元素
        let stopMatches = match.match(/<stop(.*?)\/>/g);
        if (stopMatches) {
          stopMatches.forEach(stopMatch => {
            let stopObj = { label: 'stop' };
            let stopAttributes = stopMatch.match(/\b(\w+-?\w*)\s*=\s*\"([^\"]*)\"/g);
            if (stopAttributes) {
              stopAttributes.forEach(attr => {
                let [key, value] = attr.split(/\s*=\s*/);
                key = key.trim().replace(/\"/g, ''); // 修正属性名
                value = value.replace(/\"/g, '');
                if (key === 'stop-color') {
                  stopObj[key] = value;
                } else {
                  stopObj[key] = value;
                }
              });
            }
            linearGradientObj.children.push(stopObj);
          });
        }
  
        defsArray.push({ children: [linearGradientObj] });
      });
    }
  }
  
  console.log('defs = ', defsArray);
  const svg = {
    g,
    rect,
    path,
    defs: defsArray
  }
  return svg
}
export {
  IconToSvg,
  getSvgPath
}