/*
 * @Author: mjzhu
 * @describe: 
 * @Date: 2023-10-18 10:01:27
 * @LastEditTime: 2023-11-21 15:57:58
 * @FilePath: \awx-ui\src\utils\createComponents.js
 */
function randomCondition() {
  const a = Math.random() > 0.5 ? 'alfa' : 'beta';
  const b = Math.random() > 0.5 ? '>' : '<';
  const c = Math.round(Math.random() * 100);
  return `${a} ${b} ${c}`;
}

function createTaskStep(templateItem) {
  return {
    id: templateItem.template_id,
    componentType: "task",
    type: 'task',
    name: templateItem.name,
    icon: templateItem.icon,
    properties: templateItem?.properties || {
      model: {}
    },
  };
}

function createIfStep(templateItem, _true, _false) {
  return {
    id: templateItem.template_id,
    componentType: "switch",
    type: "if",
    name: "If",
    icon: `./assets/icon-if.svg`,
    branches: {
      true: _true,
      false: _false,
    },
    properties: {
      model: {
        conditions: {
          true: {
            expression: ''
          }
        }
      }
    },
  };
}

function createContainerStep(templateItem, steps) {
  return {
    id: templateItem.id,
    componentType: "container",
    type: "loop",
    icon: `./assets/icon-loop.svg`,
    name: "Loop",
    branches: {
      loop: steps
    },
    properties: {
      model: {
        inputCollection: ''
      }
    },
    sequence: steps,
  };
}

function createParallelStep(templateItem, children) {
  return {
    id: templateItem.id,
    componentType: 'switch',
    type: 'parallel',
    icon: `./assets/icon-if.svg`,
    name: templateItem.name,
    properties: {
      model: {
        completionType: undefined,
        numCompleted:0, // 完成数量
        conditions: {
          'Condition A': {
            // '': ''
          },
          'Condition B': {
            // '': ''
          },
          'Condition C': {
            // '': ''
          }
        }
      }
    },
    branches: {
      'Condition A': [],
      'Condition B': [],
      'Condition C': []
    }
  };
}
function createSwitchStep(templateItem, children) {
  return {
    id: templateItem.id,
    componentType: 'switch',
    type: 'switch',
    icon: `./assets/icon-if.svg`,
    name: templateItem.name,
    properties: {
      model: {
        conditions: {
          'Condition A': {
            expression: ''
          },
          'Condition B': {
            expression: ''
          },
          'Condition C': {
            expression: ''
          }
        }
      }
    },
    branches: {
      'Condition A': [],
      'Condition B': [],
      'Condition C': []
    }
  };
}
export {
  createTaskStep,
  createIfStep,
  createContainerStep,
  createParallelStep,
  createSwitchStep
}