<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2022-08-19 11:18:26
 * @LastEditTime: 2023-11-03 16:37:04
 * @FilePath: \awx-ui\src\components\workflow-design\components\paramSetting\paramSetting.vue
-->
<template>
  <div class="param-setting">
    <a-button class="add-param" type="primary" @click="addFiled">
      <svg-icon class="btn-before-icon mgr6" icon-class="file-add"></svg-icon>新建参数
    </a-button>
    <a-input ref="userNameInput" v-model="keyWord" class="mgb12" @change="search" placeholder="请输入关键词">
      <svg-icon icon-class="search" class="template-search" slot="prefix" ></svg-icon>
    </a-input>
    <a-form-model ref="tableformRef" :model="form">
      <a-table class="form-table" :columns="column" :data-source="form.list" :customRow="handleClickRow" :scroll="{y: form.list.length > 10 ? 496 : ''}" :rowKey="(record, index)=>index" :pagination="false" :locale="locale">
        <template v-for="item in column" :slot="item.name" slot-scope="text, record,index">
          <div :key="item.name">
            <a-form-model-item :prop="'list.'+index+'.'+ `${item.name}`" :rules="{required: false, message: item.title + '不能为空'}">
              <!-- 名称 -->
              <template v-if="['name'].includes(item.name)">
                <a-input v-if="(statusList[`${getCurentIndex(record)}`].edit || statusList[`${getCurentIndex(record)}`][`${item.name}Focus`] || !record[`${item.name}`] )" v-model="record[`${item.name}`]" :placeholder="'请输入'+ item.title" @focus.stop="handleFocus(record, 'name')" @blur.stop="handleBlur(record, 'name')"></a-input>
                <div class="ell show-text" :title="record[`${item.name}`] ? record[`${item.name}`] : '--'" v-else>{{ record[`${item.name}`] }}</div>
              </template>
              <!-- switch -->
              <template v-if="['private', 'runtime', 'required'].includes(item.name)">
                <a-switch v-model="record[`${item.name}`]" size="small" :disabled="!statusList[`${getCurentIndex(record)}`].edit"></a-switch>
              </template>
              <!-- 类型 -->
              <template v-if="item.name === 'type'">
                <a-select v-if="(statusList[`${getCurentIndex(record)}`].edit || statusList[`${getCurentIndex(record)}`][`${item.name}Focus`])"  v-model="record[`${item.name}`]" :placeholder="'请选择'+ item.title" @change="(val) => getVal(item.name, val, index, item)" @dropdownVisibleChange="(val) => dropdownVisibleChange(val, record, 'type')">
                  <a-select-option v-for="(child, childIndex) in typeArr" :key="childIndex" :value="child.value">{{child.label}}</a-select-option>
                </a-select>
                <div class="ell show-text" v-else>{{ record[`${item.name}`] ? record[`${item.name}`] === 'text' ? '字符串' : '枚举' : '--' }}</div>
              </template>
              <!-- 选完主机组的默认值 -->
              <a-select v-if="record.type === 'host_group' && item.name === 'value'" v-model="record[`${item.name}`]" :placeholder="'请选择'+ item.title">
                <a-select-option v-for="(child, childIndex) in hostGroupList" :key="childIndex" :value="child.id+''">{{child.name}}</a-select-option>
              </a-select>
              <!-- 选完枚举的默认值 -->
              <template v-if="record.type === 'enum' && item.name === 'value'">
                <a-select v-if="(statusList[`${getCurentIndex(record)}`].edit || statusList[`${getCurentIndex(record)}`][`${item.name}Focus`] )" v-model="record[`${item.name}`]" :placeholder="'请选择'" @dropdownVisibleChange="(val) => dropdownVisibleChange(val, record, 'value')">
                  <div slot="dropdownRender" slot-scope="menu">
                    <v-nodes :vnodes="menu" />
                    <a-divider style="margin: 4px 0;" />
                    <div style="padding: 4px 8px; cursor: pointer;" @mousedown="e => e.preventDefault()" @click="addItem(item.name, index, item)">
                      <a-icon type="plus" />添加枚举值
                    </div>
                  </div>
                <a-select-option v-for="(child, childIndex) in (form.list[index].display || [])" :key="childIndex" :value="child.value">{{child.name}}</a-select-option>
              </a-select>
              <div class="ell show-text" v-else>{{ record[`${item.name}`] ? record[`${item.name}`] : '--' }}</div>
              </template>
              <!-- 选完字符串的默认值 -->
              <template v-if="record.type === 'text' && item.name === 'value'">
                <a-input v-if="(statusList[`${getCurentIndex(record)}`].edit || statusList[`${getCurentIndex(record)}`][`${item.name}Focus`] )" v-model="record[`${item.name}`]" :placeholder="'请输入'+ item.title" @focus.stop="handleFocus(record, 'value')" @blur.stop="handleBlur(record, 'value')"></a-input>
                <div class="ell show-text" :title="record[`${item.name}`] ? record[`${item.name}`] : '--'" v-else>{{ record[`${item.name}`] ? record[`${item.name}`] : '--'  }}</div>
              </template>
              <template v-if="item.name === 'description'">
                <div v-if="(statusList[`${getCurentIndex(record)}`].edit || statusList[`${getCurentIndex(record)}`][`${item.name}Focus`] )" class="flex-container">
                  <a-input v-model="record[`${item.name}`]" :placeholder="'请输入'+ item.title" @focus.stop="handleFocus(record, 'description')" @blur.stop="handleBlur(record, 'description')"></a-input>
                  <span class="mgl8 action-icon" @click="minusFiled(record)">
                    <svg-icon icon-class="file-delete" />
                  </span>
                </div>
                <div class="ell show-text" :title="record[`${item.name}`] ? record[`${item.name}`] : '--'" v-else>{{ record[`${item.name}`] ? record[`${item.name}`] : '--' }}</div>
              </template>
            </a-form-model-item>
          </div>
        </template>
      </a-table>
    </a-form-model>
    <a-modal v-if="visible" title="枚举参数" :visible="visible" :maskClosable="false" :closable="true" :confirm-loading="confirmLoading" @cancel="handleCancel" :footer="null">
      <a-form-model ref="paramFromRef" :model="paramFrom" class="enum-modal">
        <a-form-model-item label="名称" :rules="{required: false}">
          <a-input v-model="paramFrom.name" disabled></a-input>
        </a-form-model-item>
        <a-form-model-item label="枚举参数" prop="enumStr">
          <a-input type="textarea" v-model="paramFrom.enumStr"></a-input>
        </a-form-model-item>可选参数之间用英文","分隔
      </a-form-model>
      <div class="footer-param">
        <a-button class="mgr10" @click="handleCancel">取消</a-button>
        <a-button class="mgr10" type="primary" :loading="confirmLoading" @click="confirmItem">{{ '确定' }}</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { throttle } from 'lodash';
import { mapState, mapMutations, mapActions } from "vuex";

function generateRandomId() {
  const timestamp = Date.now().toString(36); // 使用时间戳生成字符串
  const randomNum = Math.random().toString(36).substr(2, 5); // 使用随机数生成字符串
  return timestamp + randomNum;
}
export default {
  components: {
    VNodes: {
      functional: true,
      render: (h, ctx) => ctx.props.vnodes,
    },
  },
  data() {
    return {
      keyWord: '',
      paramFrom: {},
      visible: false,
      confirmLoading: false,
      form: {
        list: [],
      },
      syncTableList: [],
      statusList: [],
      rules: {
        enumStr: [
          {
            required: false,
            message: "枚举参数可选值不能为空",
            trigger: ["change", "blur"],
          },
        ],
      },
      locale: {
        emptyText: <div class="empty-container">暂无自定义参数 </div>,
      },
      typeArr: [
        {
          label: "字符串",
          value: "text",
        },
        // {
        //   label: "主机组",
        //   value: "host_group",
        // },
        {
          label: "枚举",
          value: "enum",
        },
      ],
      column: [
        {
          title: "名称",
          key: "name",
          name: "name",
          width: 110,
          ellipsis: true,
          scopedSlots: { customRender: "name" },
        },
        {
          title: "类型",
          key: "type",
          width: 110,
          ellipsis: true,
          name: "type",
          scopedSlots: { customRender: "type" },
        },
        {
          title: "默认值",
          key: "value",
          width: 114,
          ellipsis: true,
          name: "value",
          scopedSlots: { customRender: "value" },
        },
        // {
        //   title: "私密参数",
        //   key: "private",
        //   name: "private",
        //   width: 90,
        //   ellipsis: true,
        //   scopedSlots: { customRender: "private" },
        // },
        {
          title: "是否必填",
          key: "required",
          name: "required",
          width: 90,
          ellipsis: true,
          scopedSlots: { customRender: "required" },
        },
        {
          title: "运行时设置",
          key: "runtime",
          width: 106,
          ellipsis: true,
          name: "runtime",
          scopedSlots: { customRender: "runtime" },
        },
        {
          title: "描述",
          key: "description",
          ellipsis: true,
          name: "description",
          scopedSlots: { customRender: "description" },
        },
      ],
    };
  },
  watch: {
    tableData: {
      handler(val) {
        // this.initTable(val);
      },
      immediate: true,
    },
    statusList: {
      handler(val) {
        // console.log(val, "状态列表");
      },
      deep: true,
      immediate: true,
    },
    "form.list": {
      handler(val) {
        const taskInfo = _.cloneDeep(this.task.stepInfo);
        const configs = taskInfo?.taskTemplateDetail?.configs || [];
        const systemParam = configs.filter((item) => item.system);
        const newMap = new Map(this.form.list.map(item => [item.uuid, item]));
        const syncedList = this.syncTableList.map(item1 => {
          const matchingItem = newMap.get(item1.uuid);
          return {
            ...item1,
            ...(matchingItem && { ...matchingItem })
          };
        });
        this.syncTableList = syncedList
        if (taskInfo?.taskTemplateDetail?.configs) {
          taskInfo.taskTemplateDetail.configs = systemParam.concat(syncedList);
          this.setStepInfo(taskInfo);
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapState({
      task: (state) => state.task, //深拷贝的意义在于watch里面可以在Watch里面监听他的newval和oldVal的变化
    }),
    hostGroupList() {
      const hostGroupList = this.task.hostGroup;
      return hostGroupList;
    },
    tableData() {
      const tableData =
        this.task.stepInfo &&
        this.task.stepInfo.taskTemplateDetail &&
        this.task.stepInfo.taskTemplateDetail.configs
          ? this.task.stepInfo.taskTemplateDetail.configs
          : [];
      return tableData;
    },
  },
  mounted() {
    this.initTable();
  },
  methods: {
    ...mapActions("task", ["setStepInfo", "setHostGroup", "setHostList"]),
    search: throttle(function () {
      const list = _.cloneDeep(this.syncTableList)
      this.form.list = list.filter(item => item.name.includes(this.keyWord))     
    }, 1000),
    handleFocus(item, key) {
      const currentIndex = this.statusList.findIndex(child => child.uuid === item.uuid)
      const filedName = `${key}Focus`;
      this.statusList[currentIndex][`${filedName}`] = true;
    },
    handleBlur(item, key) {
      const currentIndex = this.statusList.findIndex(child => child.uuid === item.uuid)
      const filedName = `${key}Focus`;
      this.statusList[currentIndex][`${filedName}`] = false;
    },
    dropdownVisibleChange (val, item, key) {
      const currentIndex = this.statusList.findIndex(child => child.uuid === item.uuid)
      const filedName = `${key}Focus`;
      this.statusList[currentIndex][`${filedName}`] = val;
    },
    handleClickRow(record, index) {
      return {
        style: {},
        on: {
          mouseenter: () => {
            const currentIndex = this.statusList.findIndex(child => child.uuid === record.uuid)
            this.statusList[currentIndex].edit = true;
          },
          mouseleave: () => {
            const currentIndex = this.statusList.findIndex(child => child.uuid === record.uuid)
            if (!this.statusList[currentIndex]['typeFocus'] && !this.statusList[currentIndex]['valueFocus']) this.statusList[currentIndex].edit = false;
          },
        },
      };
    },
    getCurentIndex (record) {
      const currentIndex = this.statusList.findIndex(child => child.uuid === record.uuid)
      return currentIndex
    },
    getVal(filed, val, index, item) {
      this.form.list[index].value = val === "text" ? "" : undefined;
    },
    addItem(filed, index, item) {
      this.paramFrom.name = this.form.list[index].name;
      let strArr = [];
      if (!this.form.list[index].display) {
        this.paramFrom.enumStr = "";
      } else {
        strArr = this.form.list[index].display.map((item) => item.name);
        this.paramFrom.enumStr = strArr.join(",");
      }
      this.visible = true;
      this.addItemObj = {
        filed,
        index,
        item,
      };
    },
    confirmItem() {
      const index = this.addItemObj.index;
      const self = this;
      this.$refs.paramFromRef.validate((valid) => {
        if (!self.paramFrom.enumStr) return this.handleCancel()
        let strArr = [...new Set(self.paramFrom.enumStr.split(","))];
        self.form.list[index].display = [];
        strArr.map((item) => {
          self.form.list[index].display.push({
            name: item,
            value: item,
          });
        });
        this.handleCancel();
      });
    },
    handleCancel(e) {
      this.visible = false;
      this.addItemObj = {};
    },
    initTable() {
      let tableData = [];
      if (
        this.task.stepInfo &&
        this.task.stepInfo.taskTemplateDetail &&
        this.task.stepInfo.taskTemplateDetail?.configs
      ) {
        this.statusList = []
        this.form.list = []
        this.syncTableList = []
        this.task.stepInfo.taskTemplateDetail?.configs.map((item) => {
          const uuid = generateRandomId()
          if (!item.system) {
            tableData.push({
              ...item,
              uuid
            });
            this.statusList.push({
              edit: false,
              nameFocus: false,
              typeFocus: false,
              descriptionFocus: false,
              valueFocus: false,
              show: true,
              uuid
            });
          }
        });
      }
      this.form.list = _.cloneDeep(tableData);
      this.syncTableList = _.cloneDeep(tableData)
    },
    addFiled(index) {
      let obj = {
        name: "",
        type: "text",
        value: "",
        private: false,
        required: false,
        description: "",
        runtime: false,
        system: false,
        uuid: generateRandomId()
      };
      this.statusList.push({
        edit: true,
        nameFocus: true,
        typeFocus: false,
        descriptionFocus: false,
        valueFocus: false,
        show: true,
        uuid: obj.uuid
      });
      this.form.list.push({ ...obj });
      this.syncTableList.push({...obj})
    },
    minusFiled(item) {
      const index = this.form.list.findIndex(child => child.uuid === item.uuid)
      this.form.list.splice(index, 1);
      this.statusList.splice(index, 1);
      this.syncTableList.splice(index, 1)
    },
    handleSubmit() {
      this.$refs.tableformRef.validate(async (valid) => {
        if (valid) {
          this.$message.success("提交成功");
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.param-setting {
  /deep/ .ant-form-item-with-help,
  /deep/ .ant-form-item {
    margin-bottom: 0;
  }
  /deep/ .ant-input,
  /deep/ .ant-select-selection {
    height: 28px;
  }
  /deep/ .ant-select-selection {
    .ant-select-selection__rendered {
      line-height: 26px;
    }
  }
  /deep/ .ant-table-tbody > tr > td {
    padding: 4px 8px;
  }
  /deep/ .ant-table-thead > tr > th {
    font-weight: 550;
  }
  .add-param {
    position: absolute;
    top: 8px;
    right: 0px;
    .btn-before-icon {
      position: relative;
      top: -1px;
    }
  }
  .empty-container {
    padding: 10px;
    color: #666;
    .empty-add {
      color: @primary-color;
      cursor: pointer;
    }
  }
  .action-icon {
    cursor: pointer;
  }
}
/deep/ .ant-modal-body {
  padding: 4px 24px 24px !important;
}
.footer-param {
  display: flex;
  justify-content: flex-end;
}
.show-text {
  height: 28px;
  padding: 4px 11px;
  font-size: 14px;
  line-height: 1.5;
}
</style>
