<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2022-08-19 11:18:26
 * @LastEditTime: 2023-10-27 15:28:40
 * @FilePath: \awx-ui\src\components\workflow-design\components\paramSetting\systemParams.vue
-->
<template>
  <div class="system-params">
    <a-input ref="userNameInput" v-model="keyWord" class="mgb12" @change="search" placeholder="请输入关键词">
      <svg-icon icon-class="search" class="template-search" slot="prefix" ></svg-icon>
    </a-input>
    <a-form-model ref="tableformRef" :model="form">
      <a-table class="form-table" :columns="column" :data-source="form.list" :paramLoading="paramLoading" :customRow="handleClickRow" :scroll="{y: form.list.length > 10 ? 496 : ''}" :rowKey="(record, index)=>index" :pagination="false" :locale="locale">
        <template v-for="item in column" :slot="item.name" slot-scope="text, record,index">
          <div :key="item.name" >
            <a-form-model-item :prop="'list.'+index+'.'+ `${item.name}`" :rules="{required: false, message: item.title + '不能为空'}">
              <!-- 名称 -->
              <template v-if="['name'].includes(item.name)">
                <a-input v-if="(statusList[`${getCurentIndex(record)}`].edit || statusList[`${getCurentIndex(record)}`][`${item.name}Focus`] || !record[`${item.name}`] )" v-model="record[`${item.name}`]" :placeholder="'请输入'+ item.title" @focus.stop="handleFocus(record, 'name')" @blur.stop="handleBlur(record, 'name')"></a-input>
                <div class="ell show-text" :title="record[`${item.name}`] ? record[`${item.name}`] : '--'" v-else>{{ record[`${item.name}`] }}</div>
              </template>
              <!-- 类型 -->
              <template v-if="item.name === 'type'">
                <div class="ell show-text">{{ record[`${item.name}`] ? record[`${item.name}`] !== 'enum' ? '字符串' : '枚举' : '--' }}</div>
              </template>
              <!-- 选完枚举的默认值 -->
              <template v-if="record.type === 'enum' && item.name === 'value'">
                <a-select v-if="(statusList[`${getCurentIndex(record)}`].edit || statusList[`${getCurentIndex(record)}`][`${item.name}Focus`] )" v-model="record[`${item.name}`]" :placeholder="'请选择'" @dropdownVisibleChange="(val) => dropdownVisibleChange(val, record, 'value')">
                  <div slot="dropdownRender" slot-scope="menu">
                    <v-nodes :vnodes="menu" />
                  </div>
                <a-select-option v-for="(child, childIndex) in (form.list[index].display || [])" :key="childIndex" :value="child.value">{{child.name}}</a-select-option>
              </a-select>
              <div class="ell show-text" v-else>{{ record[`${item.name}`] ? getLabelValue(form.list[index].display, record[`${item.name}`]) : '--' }}</div>
              </template>
                <!-- 选完字符串的默认值 -->
              <template v-if="record.type !== 'enum' && item.name === 'value'">
                <a-input v-if="(statusList[`${getCurentIndex(record)}`].edit || statusList[`${getCurentIndex(record)}`][`${item.name}Focus`] )" v-model="record[`${item.name}`]" :placeholder="'请输入'+ item.title" @focus.stop="handleFocus(record, 'value')" @blur.stop="handleBlur(record, 'value')"></a-input>
                <div class="ell show-text" :title="record[`${item.name}`] ? record[`${item.name}`] : '--'" v-else>{{ record[`${item.name}`] ? record[`${item.name}`] : '--'  }}</div>
              </template>
              <template v-if="item.name === 'description'">
                <div v-if="(statusList[`${getCurentIndex(record)}`].edit || statusList[`${getCurentIndex(record)}`][`${item.name}Focus`] )" class="flex-container">
                  <a-input v-model="record[`${item.name}`]" :placeholder="'请输入'+ item.title" @focus.stop="handleFocus(item, 'description')" @blur.stop="handleBlur(item, 'description')"></a-input>
                </div>
                <div class="ell show-text" :title="record[`${item.name}`] ? record[`${item.name}`] : '--'" v-else>{{ record[`${item.name}`] ? record[`${item.name}`] : '--' }}</div>
              </template>
            </a-form-model-item>
          </div>
        </template>
      </a-table>
    </a-form-model>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { throttle } from 'lodash';
function generateRandomId() {
  const timestamp = Date.now().toString(36); // 使用时间戳生成字符串
  const randomNum = Math.random().toString(36).substr(2, 5); // 使用随机数生成字符串
  return timestamp + randomNum;
}
export default {
  components: {
    VNodes: {
      functional: true,
      render: (h, ctx) => ctx.props.vnodes,
    },
  },
  data() {
    return {
      keyWord: '',
      paramFrom: {},
      visible: false,
      confirmLoading: false,
      paramLoading: false,
      form: {
        list: [],
      },
      syncTableList: [],
      statusList: [],
      locale: {
        emptyText: <div class="empty-container">暂无系统预定义参数 </div>,
      },
      typeArr: [
        {
          label: "字符串",
          value: "text",
        },
        {
          label: "枚举",
          value: "enum",
        },
      ],
      column: [
        {
          title: "名称",
          key: "name",
          name: "name",
          ellipsis: true,
          scopedSlots: { customRender: "name" },
        },
        {
          title: "类型",
          key: "type",
          width: 130,
          ellipsis: true,
          name: "type",
          scopedSlots: { customRender: "type" },
        },
        {
          title: "默认值",
          key: "value",
          width: 125,
          ellipsis: true,
          name: "value",
          scopedSlots: { customRender: "value" },
        },
        {
          title: "描述",
          key: "description",
          ellipsis: true,
          name: "description",
          scopedSlots: { customRender: "description" },
        },
      ],
    };
  },
  watch: {
    tableData: {
      handler(val) {
      },
      immediate: true,
    },

    "form.list": {
      handler(val) {
        const taskInfo = _.cloneDeep(this.task.stepInfo);
        const configs = taskInfo.taskTemplateDetail.configs || [];
        const customParams = configs.filter((item) => !item.system);
        const newMap = new Map(this.form.list.map(item => [item.uuid, item]));
        const syncedList = this.syncTableList.map(item1 => {
          const matchingItem = newMap.get(item1.uuid);
          return {
            ...item1,
            ...(matchingItem && { ...matchingItem })
          };
        });
        this.syncTableList = syncedList
        taskInfo.taskTemplateDetail.configs = customParams.concat(syncedList);
        this.setStepInfo(taskInfo);
      },
      deep: true,
    },
  },
  computed: {
    ...mapState({
      task: (state) => state.task, //深拷贝的意义在于watch里面可以在Watch里面监听他的newval和oldVal的变化
    }),
    tableData() {
      const tableData =
        this.task.stepInfo &&
        this.task.stepInfo.taskTemplateDetail &&
        this.task.stepInfo.taskTemplateDetail.configs
          ? this.task.stepInfo.taskTemplateDetail.configs
          : [];
      return tableData;
    },
  },
  mounted() {
    this.initTable();
  },
  methods: {
    ...mapActions("task", ["setStepInfo", "setHostGroup", "setHostList"]),
    search: throttle(function () {
      const list = _.cloneDeep(this.syncTableList)
      this.form.list = list.filter(item => item.name.includes(this.keyWord))     
    }, 1000),
    handleFocus(item, key) {
      const currentIndex = this.statusList.findIndex(child => child.uuid === item.uuid)
      const filedName = `${key}Focus`;
      this.statusList[currentIndex][`${filedName}`] = true;
    },
    handleBlur(item, key) {
      const currentIndex = this.statusList.findIndex(child => child.uuid === item.uuid)
      const filedName = `${key}Focus`;
      this.statusList[currentIndex][`${filedName}`] = false;
    },
    dropdownVisibleChange (val, item, key) {
      const currentIndex = this.statusList.findIndex(child => child.uuid === item.uuid)
      const filedName = `${key}Focus`;
      this.statusList[currentIndex][`${filedName}`] = val;
    },
    getLabelValue (arr, value) {
      const obj = arr.find(item => item.value === value)
      return obj?.name || ''
    },
    handleClickRow(record, index) {
      return {
        style: {},
        on: {
          mouseenter: () => {
            const currentIndex = this.statusList.findIndex(child => child.uuid === record.uuid)
            this.statusList[currentIndex].edit = true;
          },
          mouseleave: () => {
            const currentIndex = this.statusList.findIndex(child => child.uuid === record.uuid)
            if (!this.statusList[currentIndex]['typeFocus'] && !this.statusList[currentIndex]['valueFocus']) this.statusList[currentIndex].edit = false;
          },
        },
      };
    },
    getCurentIndex (record) {
      const currentIndex = this.statusList.findIndex(child => child.uuid === record.uuid)
      return currentIndex
    },
    getVal(filed, val, index, item) {
      this.form.list[index].value = val === "text" ? "" : undefined;
    },
    handleCancel(e) {
      this.visible = false;
      this.addItemObj = {};
    },
   async initTable() {
      let tableData = [];
      const configs =  this.task?.taskInfo?.taskTemplateDetail?.configs || []
      this.statusList = []
      this.form.list = []
      this.syncTableList = []
      configs.map((item) => {
        if (item.system) {
          const uuid = generateRandomId()
          tableData.push({
            ...item,
            uuid
          });
        }
      });
      if (!tableData.length) {
        this.paramLoading = true
        const res =  await this.$axiosGet(global.API.getSystemParams, {page:1, pageSize: 1000})
        this.paramLoading = false
        tableData = res?.data?.results.map(item => {
          const uuid = generateRandomId()
          return {
            ...item,
            uuid
          }
        })
      }
      this.form.list = _.cloneDeep(tableData);
      this.syncTableList = _.cloneDeep(tableData)
      tableData.map(item => {
        this.statusList.push({
          edit: false,
          nameFocus: false,
          typeFocus: false,
          descriptionFocus: false,
          valueFocus: false,
          show: true,
          uuid: item.uuid
        });
      })
    },
    handleSubmit() {
      this.$refs.tableformRef.validate(async (valid) => {
        if (valid) {
          this.$message.success("提交成功");
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.system-params {
  /deep/ .ant-form-item-with-help,
  /deep/ .ant-form-item {
    margin-bottom: 0;
  }
  /deep/ .ant-input,
  /deep/ .ant-select-selection {
    height: 28px;
  }
  /deep/ .ant-select-selection {
    .ant-select-selection__rendered {
      line-height: 26px;
    }
  }
  /deep/ .ant-table-tbody > tr > td {
    padding: 4px 8px;
  }
  /deep/ .ant-table-thead > tr > th {
    font-weight: 550;
  }
  .add-param {
    position: absolute;
    top: 8px;
    right: 0px;
    .btn-before-icon {
      position: relative;
      top: -1px;
    }
  }
  .empty-container {
    padding: 10px;
    color: #666;
    .empty-add {
      color: @primary-color;
      cursor: pointer;
    }
  }
  .action-icon {
    cursor: pointer;
  }
}
/deep/ .ant-modal-body {
  padding: 4px 24px 24px !important;
}
.footer-param {
  display: flex;
  justify-content: flex-end;
}
.show-text {
  height: 28px;
  margin: 4px 0;
  padding: 4px 11px;
  font-size: 14px;
  line-height: 1.5;
}
</style>
