import { render, staticRenderFns } from "./appItem.vue?vue&type=template&id=55655568&scoped=true"
import script from "./appItem.vue?vue&type=script&lang=js"
export * from "./appItem.vue?vue&type=script&lang=js"
import style0 from "./appItem.vue?vue&type=style&index=0&id=55655568&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55655568",
  null
  
)

export default component.exports