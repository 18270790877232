<!--
 * @Author: mjzhu
 * @Date: 2022-06-13 14:04:05
 * @LastEditTime: 2024-06-06 19:39:57
 * @FilePath: \awx-ui\src\components\workflow-design\components\authConfig\index.vue
-->
<template>
  <div class="auth-config-page">
    <a-spin :spinning="spinning" v-if="hasAuthConfig">
      <div v-if="selectAuthList.length && showAuthList">
        <div class="auth-config-list">
          <div class="auth-config-item" v-for="(item, index) in selectAuthList" :key="index">
            <div :class="[authId === item.id ? 'opt-item-selected' : '', 'opt-item']" @click="selectAuth(item)">
              <div class="name" :title="item.name">{{ item.name }}</div>
              <div class="description" :title="item.description">{{ item.description }}</div>
            </div>
          </div>
          <div class="footer-btn">
            <a-button v-if="this.selectAuthList.length" @click="createNewAuth" type="primary">新建</a-button>
          </div>
         </div>
      </div>
      <div v-else>
        <AuthConfigModel ref="commonForm" class="auth-config-form" :selectApp="selectApp" :authModel="model" :authSelectedTemplateObj="selectedTemplateObj" :authDetail="authDetail" @getFormData="getFormData" />
        <div class="footer-param" v-if="!spinning">
          <a-button class="mgr10" @click="handleCancel">取消</a-button>
          <a-button class="mgr10" type="primary" :loading="confirmLoading" @click="confirmItem">{{ '确定' }}</a-button>
        </div>
      </div>
    </a-spin>
    <div v-else> 
      <div style="height: calc(100vh - 220px)">
        <NoData />
      </div>
      <div class="footer-btn">
        <a-button @click="next" type="primary">下一步</a-button>
      </div>
    </div>
  </div>
</template>
  <script>
import { mapState, mapMutations, mapActions } from "vuex";
import AuthConfigModel from '@/pages/authManage/commonForm.vue'
import NoData from "../../components/noData.vue";
import { validateStep } from '../validate'

export default {
  name: "AuthConfigList",
  components: { NoData, AuthConfigModel },
  props: {
    taskType: {
      type: String,
      default: "task",
    },
  },
  watch: {
    taskId: {
      handler(val) {
        if (val) {
          this.eidtFlag = true;
        }
      },
      immediate: true,
    },
    // 从模板那边进来的 隐藏右边的模板
    taskType: {
      handler(val) {
        if (val === "template") {
          this.eidtFlag = true;
        }
      },
      immediate: true,
    },
    "task.selectStepId": {
      handler(val) {
        if (val && this.task?.stepInfo?.taskTemplateDetail?.stepInfo.id === val) {
          const taskType = this.task?.stepInfo?.taskTemplateDetail?.stepInfo?.properties?.type
          // todo  如果是调度的不走这个逻辑
          if (taskType === 'schedule') return false
          this.getAuthListByAppid(this.currentAppId)
        }
      },
    },
    "currentStepsIndex": {
      handler(val) {
        if (val === 1 && this.task?.stepInfo?.taskTemplateDetail?.stepInfo.id === this.currentAppId) {
          const taskType = this.task?.stepInfo?.taskTemplateDetail?.stepInfo?.properties?.type
          // todo  如果是调度的不走这个逻辑
          if (taskType === 'schedule') return false
          this.getAuthListByAppid(this.currentAppId)
        }
     
      },
    },
  },
  data() {
    return {
      nextLoading: false,
      eidtFlag: false,
      spinning: false,
      selectAuthList: [],
      showAuthList: true,
      selectApp: {},
      model: {},
      authDetail: {},
      hasAuthConfig: true,
      selectedTemplateObj: {},
      confirmLoading: false
    };
  },
  computed: {
    ...mapState({
      task: (state) => state.task, //深拷贝的意义在于watch里面可以在Watch里面监听他的newval和oldVal的变化
    }),
    taskId() {
      const taskId = this.task.stepInfo.task_id || "";
      return taskId;
    },
    currentAppId() {
      const currentAppId = this.task?.stepInfo?.taskTemplateDetail?.stepInfo?.properties?.application_id || ''
      return currentAppId
    },
    // 选中的授权id
    authId() {
      const conn_id = this.task?.stepInfo?.taskTemplateDetail?.stepInfo?.properties?.conn?.conn_id
      return conn_id
    },
  },
  methods: {
    ...mapActions("task", ["setSelectTemplateIndex", "setCurrentOptIndex", "setCurrentPageIndex", "setStepInfo"]),
    async confirmItem () {
      const form = this.$refs.commonForm.form
      if (!form.name) return this.$message.error('请输入授权名称')
      const requiredArr = this.$refs.commonForm.authSelectedTemplateObj.schema?.schema?.required
      const model = { 
        ...this.formData,
      }
      let flag = true
      for (let i = 0; i < requiredArr.length; i++) {
        if (!model[`${requiredArr[i]}`]) {
          flag = false
          let str = ''
          if (this.$refs.commonForm.authSelectedTemplateObj.schema && this.$refs.commonForm.authSelectedTemplateObj.schema.schema && this.$refs.commonForm.authSelectedTemplateObj.schema.schema.properties && this.$refs.commonForm.authSelectedTemplateObj.schema.schema.properties[`${requiredArr[i]}`]) {
            str = '请输入' + this.$refs.commonForm.authSelectedTemplateObj.schema.schema.properties[`${requiredArr[i]}`].title
          }
          if (str) this.$message.error(str)
          break; // 当元素为 3 时跳出循环
        }
      }
      if (!flag) return false
      this.confirmLoading = true
      const params = {
        name: form.name,
        application_id: this.currentAppId,
        model: model,
        schema: this.selectedTemplateObj.schema,
        description: form.description
      }
      if (this.authDetail.id) {
        params.id = this.authDetail.id
      }
      let res = this.authDetail.id ? await this.$axiosPut(
        global.API.getApp + '/' + this.authDetail.app_id + '/connections/' + this.authDetail.id,
        params
      ) : await this.$axiosJsonPost(
        global.API.getApp + '/' + this.currentAppId+ '/connections',
        params
      );
      this.confirmLoading = false
      if ([200, 201, 204, 202].includes(res.status)) {
        this.$message.success(this.authDetail?.id ? "修改成功" : "创建成功");
        // 刷新列表
        this.getAuthListByAppid(this.currentAppId)
        this.handleCancel()
        this.selectApp =  {
          name: '',
          id: ''
        }
        this.getConnections()
      }
    },
    createNewAuth () {
      this.showAuthList = false
    },
    handleCancel () {
      this.showAuthList = true
    },
    getFormData (formData) {
      this.formData = formData
    },
    next () {
      if (this.selectAuthList.length > 0 && !this.authId) {
        return this.$message.error('请选择授权')
      }
      const stepInfo = _.cloneDeep(this.task.stepInfo);
      const properties = stepInfo?.taskTemplateDetail?.stepInfo?.properties || {}
      properties['currentStepsIndex'] = 3
      this.setStepInfo(stepInfo);
    },
    getAuthListByAppid(id) {
      if (!id) return false
      this.spinning = true;
      this.$axiosGet(global.API.getApp + "/" + id + "/connections").then(
        (res) => {
          this.spinning = false;
          if ([200, 201, 204, 202].includes(res.status)) {
            this.selectAuthList = res.data.results;
            this.selectApp =  {
              name: this.task.name,
              id: id
            }
          }
        }
      );
    },
    getAuthSchema (id) {
      if (!id) return false
      this.spinning = true
      const params = {
        op_type: 2,
        application_id: id
      }
      this.$axiosGet(global.API.getStepList, params).then((res) => {
        this.spinning = false
        if ([200, 201, 204, 202].includes(res.status)) {
         if (res.data.results && res.data.results.length) {
            this.hasAuthConfig = true
            this.selectedTemplateObj= {
              ...res.data.results[0],
              schema: res.data.results[0].schema
            }
            this.model = res.data.results[0].model
            this.authDetail = {}
          } else {
            this.hasAuthConfig = false
            this.selectApp = {
              id: '',
              name: ''
            }
          }
        }        
      });
    },
    getConfigByStepsId (id, stepInfo, selectItem) {
      this.$axiosGet(global.API.getConfigByStepsId + "/" + id +'/').then(
        (res) => {
          this.spinning = false;
          if ([200, 201, 204, 202].includes(res.status)) {
            const obj = {
              ...stepInfo.properties,
              ...res.data,
              conn: {
                conn_id: selectItem.id
              },
              icon: stepInfo.properties.icon || res.data.icon,
              application_name: stepInfo.properties.application_name,
              currentStepsIndex: 3
            }
            stepInfo.properties = obj
            let taskInfo = _.cloneDeep(this.task.stepInfo);
            taskInfo.taskTemplateDetail.stepInfo = stepInfo;
            this.setStepInfo(taskInfo);
            this.$EventBus.$emit('updateStepsProperties', taskInfo.taskTemplateDetail.stepInfo.properties)
            this.$EventBus.$emit('updateStepsNameByStepId', res.data.name)
          }
        }
      );
    },
    // 选择操作
    selectAuth(templateItem) {
      const selectItem = _.cloneDeep(templateItem)
      const stepInfo = _.cloneDeep(this.task.stepInfo)
      const obj = {
        ...stepInfo.taskTemplateDetail.stepInfo.properties,
        icon: stepInfo.taskTemplateDetail.stepInfo.properties.icon || selectItem.icon,
        application_name: stepInfo.taskTemplateDetail.stepInfo.properties.application_name
      }
      stepInfo.taskTemplateDetail.stepInfo.properties = obj
      this.getConfigByStepsId(obj.id, stepInfo.taskTemplateDetail.stepInfo, selectItem)
    },
  },
  mounted() {
    this.getAuthListByAppid(this.currentAppId)
    this.getAuthSchema(this.currentAppId)
  },
};
</script>
  <style lang="less" scoped>
.auth-config-page {
  position: relative;
  .auth-config-list {
    overflow-y: auto;
    padding-right: 55px;
    max-height: calc(100vh - 220px); 
    height: calc(100vh - 220px);
    margin-top: 20px;
    .auth-config-item {
      .opt-item {
        padding: 16px 20px;
        cursor: pointer;
        widows: 100%;
        height: 78px;
        background: #ffffff;
        border: 1px solid rgba(219, 229, 249, 1);
        box-shadow: 0px 2px 6px 0px rgba(200, 212, 233, 0.1);
        border-radius: 6px;
        font-size: 14px;
        color: #7D8292;
        letter-spacing: 0;
        font-weight: 400;
        margin-bottom: 12px;
        .name {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: #242B3A;
          max-width: 610px;
        }
        .description {
          max-width: 610px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: #7D8292;
          margin-top: 4px;
        }
      }
      .opt-item-selected, .opt-item:hover {
        border: 1px solid rgba(58, 116, 244, 0.75);
        box-shadow: 0px 2px 8px 0px;
      }
    }
  }
  .actions-empty {
    text-align: center;
    &-icon {
      height: 528px;
      width: 276px;
    }
  }
  .operation-box__tip {
    color: #8a8e99;
    font-size: 14px;
    margin-top: 30px;
    margin: 30px 20px 0 0;
    .devui-link {
      color: #526ecc;
    }
  }
  .submit-template {
    margin: 30px 20px 0 0;
    width: calc(100% - 20px);
    opacity: 0.9;
    background: #eef2f7;
    border-radius: 4px;
    font-size: 14px;
    color: #5a5a69;
    letter-spacing: 0;
    font-weight: 400;
  }
  .footer-btn {
    position: absolute;
    bottom: -36px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    button {
      width: 240px;
      margin: 0 auto;
    }
  }
  .auth-config-form {
    overflow-y: auto;
    height: calc(100vh - 20px);
    max-height: calc(100vh - 200px); 
  }
  .footer-param {
    display: flex;
    justify-content: center;
  }
}
</style>