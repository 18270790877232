<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2023-10-26 11:11:25
 * @LastEditTime: 2023-11-14 20:19:51
 * @FilePath: \awx-ui\src\components\workflow-design\components\node\loop.vue
-->
<template>
  <div class="workflow-if-node">
    <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item style="margin-bottom: 0px" v-bind="labelCol" label="循环条件">
        <!-- onkeyup="this.value=this.value.replace(/[^\w_]/g,'');" -->
        <a-input v-model="form.inputCollection" placeholder="请输入" />
      </a-form-model-item>
    </a-form-model>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  data() {
    return {
      formItemLayoutWithOutLabel: {
        wrapperCol: {
          xs: { span: 24, offset: 0 },
          sm: { span: 19, offset: 5 },
        },
      },
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
      form: {
        inputCollection: "",
      },
    };
  },
  watch: {
    "form.inputCollection": {
      handler(val) {
        this.handlerFlowChange();
      },
      deep: true,
    },
  },
  computed: {
    ...mapState({
      task: (state) => state.task, //深拷贝的意义在于watch里面可以在Watch里面监听他的newval和oldVal的变化
    }),
  },
  mounted() {
    const stepInfo = _.cloneDeep(this.task.stepInfo);
    this.form.inputCollection =  stepInfo.taskTemplateDetail.stepInfo.properties["model"].inputCollection
  },
  methods: {
    ...mapActions("task", ["setStepInfo"]),
    handlerFlowChange() {
      const stepInfo = _.cloneDeep(this.task.stepInfo);
      stepInfo.taskTemplateDetail.stepInfo.properties["model"].inputCollection =
        this.form.inputCollection;
      this.setStepInfo(stepInfo);
      this.$EventBus.$emit(
        "updateStepsProperties",
        stepInfo.taskTemplateDetail.stepInfo.properties
      );
    },
  },
};
</script>
<style lang="less" scoped>
.workflow-if-node {
  padding-right: 55px;
  .name {
    font-size: 18px;
    font-weight: 550;
  }
}
</style>