<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2023-09-05 16:02:48
 * @LastEditTime: 2023-09-11 15:42:45
 * @FilePath: \awx-ui\src\components\createTask\deploystep\notice\msg.vue
-->
<template>
  <a-form-model v-if="form" :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
    <a-form-model-item label="手机号码">
      <a-input v-model="form.object.phone_num" placeholder="请输入" />
    </a-form-model-item>
    <a-form-model-item label="运行状态">
      <a-select v-model="form.events" mode="multiple" placeholder="请选择">
        <a-select-option value="workflow_success">任务执行成功</a-select-option>
        <a-select-option value="workflow_fail">任务执行失败</a-select-option>
        <a-select-option value="workflow_update">更新任务配置</a-select-option>
        <a-select-option value="workflow_delete">删除任务</a-select-option>
      </a-select>
    </a-form-model-item>
    <a-form-model-item label="通知内容">
      <a-select v-model="form.contents" mode="multiple" placeholder="请选择">
        <a-select-option value="name">名称</a-select-option>
        <a-select-option value="execution_result">执行结果</a-select-option>
        <a-select-option value="user">执行用户</a-select-option>
        <a-select-option value="execution_time">执行时间</a-select-option>
      </a-select>
    </a-form-model-item>
  </a-form-model>
</template>
<script>
export default {
  model: {
    prop: "form",
    event: "change",
  },
  props: {
    form: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  watch: {
    form: {
      handler(val) {
        console.log(val, 'sdsadsad')
        this.$emit('getNotify', 'sms', val)
      },
      deep: true
    }
  },
  data() {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
    };
  },
  methods: {
  },
};
</script>
