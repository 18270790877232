<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2023-06-02 14:43:16
 * @LastEditTime: 2024-01-26 18:08:53
 * @FilePath: \awx-ui\src\pages\pipeline\flow-design.vue
-->
<template>
  <div>
    <div id="placeholder" style="height: calc(100vh - 100px)"></div>
    <!-- <a-button @mousedown="handler" class='test-icon'>测试</a-button> -->
  </div>
</template>

<script>
import {
  Designer,
  Vector,
} from "@/components/workflow-design/lib/esm/index.js";
import "@/components/workflow-design/css/designer.css";
import "@/components/workflow-design/css/designer-light.css";
import "@/components/workflow-design/css/designer-dark.css";
import { DesignerStepComponents } from "@/components/workflow-design/flow-design.js";
import { mapState, mapMutations, mapActions } from "vuex";
import { createParallelStep, createTaskStep, createIfStep, createContainerStep, createSwitchStep} from '@/utils/createComponents.js'

function toolboxGroup(name) {
  return {
    name,
    steps: [
      createIfStep({template_id: null}, [], []),
      createContainerStep({template_id: null}, []),
			createSwitchStep({template_id: null, name: 'Switch'}, []),
			createParallelStep({template_id: null, name: 'Parallel'}, [])
    ],
  };
}

let designer;
let designerStepComponentsVm = null;

function loadState() {
  return {
    definition: getStartDefinition(),
  };
}

const initialState = loadState();

const configuration = {
  // undoStackSize: 20,
  // undoStack: initialState.undoStack,
  toolbox: {
    groups: [toolboxGroup("Logic")],
  },
  controlBar: true,
  steps: {
    isDuplicable: () => true,
    iconUrlProvider: (_, icon) => {
      return icon;
    },
    isDeletable: (step, parentSequence) => {
			// return !step.properties['disabledDelete'];
      return true
		}
  },
  runStatus: {
		step: (step) => {
			return {
				runFlag: step.properties['runFlag'],
				runStatus: step.properties['runStatus']
			}
		}
	},
  validator: false,
  editors: false,
};

function getStartDefinition() {
  return {
    properties: {},
    sequence: [],
  };
}
export default {
  data() {
    return {};
  },
  props: {
    readOnly: String 
  },
  components: {},
  computed: {
    ...mapState({
      task: (state) => state.task, //深拷贝的意义在于watch里面可以在Watch里面监听他的newval和oldVal的变化
    }),
    taskId() {
      const taskId = this.task.stepInfo.task_id || "";
      return taskId;
    },
  },
  mounted() {
  },
  methods: {
    ...mapActions("task", [
      "setTaskInfo",
      "setShowStepsList",
      "setSelectTemplateIndex",
      "setCurrentOptIndex",
      "setAddTemplateIndex",
      "setCurrentPageIndex",
      "setStepInfo",
      "setSelectStepId",
    ]),
    getEchoDefinition (status) {
      const sequence = []
      const operations = this.task.stepInfo?.taskTemplateDetail?.operations || []
      // 第一个是触发器不让删除
      if (operations.length > 0) {
        operations[0].disabledDelete = true
      }
      this.deepObj(operations, sequence, status)
      return {
        properties: {},
        sequence,
      }
    },
    initDesigner (type, step, status) {
      const placeholder = document.getElementById("placeholder");
      let definition = type === 'edit' ? this.getEchoDefinition(status) : {
        properties: {},
        sequence: [{...step}],
      }
      if (this.readOnly === 'true') {
        configuration.offSetX = 0
        configuration.toolbox = false
        configuration.isReadonly = true
        definition.sequence.forEach(item => {
          if (item.componentType === 'container' && item.branches && item.branches.loop) {
            item.sequence = item.branches.loop
          }
        }) 
      } else {
        configuration.offSetX = 700
        configuration.toolbox = {
          groups: [toolboxGroup("Logic")]
        }
        configuration.isReadonly = false
      }
      // 销毁一下 重新登录
      if (type === 'edit') {
        if (designer && designer.destroy) designer.destroy()
        designer = null
      }
      designer = Designer.create(
        placeholder,
        definition,
        configuration
      );
      if (this.readOnly === 'true') {
        // designer.api.viewport.resetViewport(0)
      } else {
        // designer.api.viewport.resetViewport(600)
      }
      designerStepComponentsVm = new DesignerStepComponents(designer);
      this.addEventListener();
    },
    deepObj(tgr, rs, status) {
      // status: 代表查看日志的时候运行节点的状态Map集合
      for (let i = 0; i < tgr.length; i++) {
        const new_branches = [];
        const item = tgr[i];
        const new_item = item.model.nodeObj;
        new_item['branches'] = {};
        new_item.properties = {
          model: {...item['model']},
          ...item
        }
        if (status) {
          if (Object.prototype.hasOwnProperty.call(status, new_item.id)) {
            console.log('new_item.id', new_item.id)
            new_item.properties.runFlag = true
            new_item.properties.runStatus = status[`${new_item.id}`].status
          }
        }
        delete new_item['properties']['model']['nodeObj']
        rs.push(new_item);
        const branches = Object.values(tgr[i].branches || {});
        const keys = Object.keys(tgr[i].branches || {});
        if (branches.length) {
          for (let index = 0; index < branches.length; index++) {
            const new_child_branches = [];
            const child_branches = branches[index];
            if (child_branches.length) {
              this.deepObj(child_branches, new_child_branches, status);
            }
            new_branches.push(new_child_branches);
          }
        }
        keys.forEach((key, ii) => {
          new_item.branches[key] = new_branches[ii]
        })
      }
    },
    addEventListener() {
      designer.onDefinitionChanged.subscribe((newDefinition) => {
        console.log(
          "the definition has changed",
          newDefinition
        );
      });
      designer.onSelectedStepIdChanged.subscribe((stepId) => {
        if (this.readOnly) {
          const step = stepId ? designer.view.workspace.getComponentByStepId(stepId) : null
          return this.$EventBus.$emit('openLogByStepId', stepId, step)
        }
        this.hanedlerNodeChange(stepId);
      });
      designer.onHandlerMouseFocus.subscribe((stepId) => {
        this.$EventBus.$emit('handlerMouseFocus')
      });
      window.designer = designer;
      // 监听右侧操作之后同步操作的step的数据
      this.$EventBus.$on("updateStepsProperties", (properties) => {
        designerStepComponentsVm.updateStepsPropertiesByStepId(properties);
      });
      // 更新当前任务节点
      this.$EventBus.$on("updateStepSelfChangedByStepId", (e, step) => {
        designerStepComponentsVm.updateStepSelfChangedByStepId(step);
        const stepInfo = _.cloneDeep(this.task.stepInfo);
        if (step) {
          stepInfo.taskTemplateDetail.stepInfo = {
            ...step,
            properties: { ...step.properties, currentStepsIndex: 1 },
          };
          this.setStepInfo(stepInfo);
        }
      });
      // 修改名称
      this.$EventBus.$on("updateStepsNameByStepId", (name) => {
        designerStepComponentsVm.updateStepsNameByStepId(name);
      });
      this.$EventBus.$on("updateStepsChildrenByStepId", (name, properties, oldName) => {
        designerStepComponentsVm.updateStepsChildrenByStepId(name, properties, oldName);
      });
      this.$EventBus.$on("deleteStepsChlidren", (name, properties) => {
        designerStepComponentsVm.deleteStepsChlidren(name, properties);
      });
      this.$EventBus.$on("setOffsetX", (x) => {
        designer.state.setOffsetX(x);
        designer.api.viewport.resetViewport(x)
      });
      this.$EventBus.$on("tryDrag", (e, step) => {
        const v = new Vector(e.clientX, e.clientY);
        designer.api.toolbox.tryDrag(v, step);
      });
    },
    handler(e) {
      updateStepsProperties();
    },
    // 节点的拖动 删除 新增处理
    hanedlerNodeChange(stepId) {
      setTimeout(() => {
        if (stepId) {
          const step = designer.view.workspace.getComponentByStepId(stepId);
          const stepInfo = _.cloneDeep(this.task.stepInfo);
          if (step) {
            let currentStepsIndex = 1
            if (step.step.properties.application_id) currentStepsIndex = 1
            if (step.step.properties.application_id && step.step.properties.id) currentStepsIndex = 3
            stepInfo.taskTemplateDetail.stepInfo = {
              ...step.step,
              properties: { ...step.step.properties, currentStepsIndex: currentStepsIndex },
            };
            this.setStepInfo(stepInfo);
            this.setSelectStepId(stepId);
            designerStepComponentsVm.updateStepsPropertiesByStepId(
              stepInfo.taskTemplateDetail.stepInfo.properties
            );
          }
        } else {
          const stepInfo = _.cloneDeep(this.task.stepInfo);
          stepInfo.taskTemplateDetail.stepInfo = {
            properties: { currentStepsIndex: 0 },
          };
          this.setStepInfo(stepInfo);
          this.setSelectStepId("");
        }
      });
    },
  },
  beforeDestroy() {
    this.$EventBus.$off("tryDrag");
    this.$EventBus.$off("updateStepsProperties");
    this.$EventBus.$off("updateStepsChildrenByStepId");
    this.$EventBus.$off("deleteStepsChlidren");
    this.$EventBus.$off("updateStepsNameByStepId");
    this.$EventBus.$off("setOffsetX");
  },
};
</script>
<style scoped lang='less'>
.test-icon {
  position: absolute;
  top: 20px;
  right: 20px;
}
</style>