var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"template-item",class:{'template-item-selected': _vm.selectedTemplateId === _vm.templateItem.id,  'already-selected': _vm.selectStepId, dragging: !!_vm.selectStepId, 'disabled-trigger' :  _vm.handlerClass(_vm.templateItem)},on:{"mousedown":($e) => _vm.selectedTemplate($e),"click":($e) => _vm.changeTemplate($e)}},[_c('span',{staticClass:"template-logo"},[(_vm.templateItem.id !== '_blank' && !_vm.IsColorIcon(_vm.templateItem.icon) && !_vm.IsNetWorkIcon(_vm.templateItem.icon))?_c('img',{attrs:{"src":_vm.templateItem.icon,"alt":""}}):_vm._e(),(_vm.templateItem.id !== '_blank' && _vm.IsColorIcon(_vm.templateItem.icon) && !_vm.IsNetWorkIcon(_vm.templateItem.icon))?_c('div',{staticClass:"icon-item",style:({
      'background': _vm.templateItem.icon,
      'text-align': 'center',
      'width': '32px',
      'height': '32px',
      'border-radius': '8px',
      'margin': 'auto'
    })},[_c('span',{style:({
        'color': '#fff',
        'font-size': _vm.iconColorName(_vm.templateItem.name).length === 1 ? '16px' : '12px',
        'display': 'block',
        'width': '100%',
        'height': '100%',
        'line-height': '32px'
      })},[_vm._v(_vm._s(_vm.iconColorName(_vm.templateItem.name))+" ")])]):_vm._e(),(_vm.templateItem.id !== '_blank' && !_vm.IsColorIcon(_vm.templateItem.icon) && _vm.IsNetWorkIcon(_vm.templateItem.icon))?_c('iconifyIcon',{staticStyle:{"font-size":"24px"},attrs:{"icon":_vm.iconNetWorkName(_vm.templateItem.icon)}}):_vm._e(),(_vm.templateItem.id === '_blank')?_c('svg-icon',{staticStyle:{"font-size":"44px","margin":"auto","display":"inline-block"},attrs:{"icon-class":"task-blank"}}):_vm._e()],1),_c('div',{staticClass:"template-text-info"},[_c('div',{staticClass:"template-title over-flow-ellipsis",style:({'max-width': _vm.templateType === 'app' ? '116px': '125px', 'font-size': _vm.templateType === 'app' ? '14px': '16px'}),attrs:{"title":_vm.templateItem.name}},[_vm._v(_vm._s(_vm.templateItem.name))])])])
}
var staticRenderFns = []

export { render, staticRenderFns }