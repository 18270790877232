<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2023-09-04 20:04:09
 * @LastEditTime: 2023-11-01 15:11:06
 * @FilePath: \awx-ui\src\components\workflow-design\components\notice\index.vue
-->
<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2023-09-04 20:04:09
 * @LastEditTime: 2023-09-06 15:56:04
 * @FilePath: \awx-ui\src\components\createTask\deploystep\notice\index.vue
-->
<template>
  <div class="notice">
    <a-collapse :bordered="false" v-model="activeKey">
      <a-collapse-panel key="email" :showArrow="false">
        <template #header>
          <div class="custom-header" @click.stop="togglePanel('email')">
            <div class="flex-bewteen-container">
              <div class="flex-container">
                <a-icon :type="isActive['email'] ? 'down' : 'right'" class="header-icon mgr8" />
                <span class="title">
                  邮件动态
                  <span class="mgl12 tips">提供应用动态邮件推送能力</span>
                </span>
              </div>
              <div>
                <a-switch :checked="notify[`${getCurrentIndex('email')}`].enabled" @change="(val, $e) => changeEnable('email', val, $e)"></a-switch>
              </div>
            </div>
          </div>
        </template>
        <Email @getEventType="getEventType" />
      </a-collapse-panel>
      <a-collapse-panel key="sms" :showArrow="false">
        <template #header>
          <div class="custom-header" @click.stop="togglePanel('sms')">
            <div class="flex-bewteen-container">
              <div class="flex-container">
                <a-icon :type="isActive['sms'] ? 'down' : 'right'" class="header-icon mgr8" />
                <span class="title">
                  短信
                  <span class="mgl12 tips">提供应用动态短信推送能力</span>
                </span>
              </div>
              <div>
                <a-switch :checked="notify[`${getCurrentIndex('sms')}`].enabled" @change="(val, $e) => changeEnable('sms', val, $e)"></a-switch>
              </div>
            </div>
          </div>
        </template>
        <Msg :form="notify[`${getCurrentIndex('sms')}`]" @getNotify="getNotify" />
      </a-collapse-panel>
      <a-collapse-panel key="dingtalk" :showArrow="false">
        <template #header>
          <div class="custom-header" @click.stop="togglePanel('dingtalk')">
            <div class="flex-bewteen-container">
              <div class="flex-container">
                <a-icon :type="isActive['dingtalk'] ? 'down' : 'right'" class="header-icon mgr8" />
                <span class="title">
                  钉钉
                  <span class="mgl12 tips">提供应用动态钉钉推送能力</span>
                </span>
              </div>
              <div>
                <a-switch :checked="notify[`${getCurrentIndex('dingtalk')}`].enabled" @change="(val, $e) => changeEnable('dingtalk', val, $e)"></a-switch>
              </div>
            </div>
          </div>
        </template>
        <DingTalk :form="notify[`${getCurrentIndex('dingtalk')}`]" @getNotify="getNotify" />
      </a-collapse-panel>
    </a-collapse>
  </div>
</template>

<script>
import Email from "./email.vue";
import Msg from "./msg.vue";
import DingTalk from './dingtalk.vue'
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  components: {
    Email,
    Msg,
    DingTalk
  },
  data() {
    return {
      isActive: { email: true }, // 记录面板的展开状态
      notify: [],
      activeKey: ["email"],
    };
  },
  computed: {
    ...mapState({
      task: (state) => state.task, //深拷贝的意义在于watch里面可以在Watch里面监听他的newval和oldVal的变化
    }),
  },
  created() {
    this.initNotify();
  },
  methods: {
    ...mapActions("task", [
      "setStepInfo",
      "setShowStepsList",
      "setSelectTemplateIndex",
      "setCurrentOptIndex",
      "setAddTemplateIndex",
      "setCurrentPageIndex",
    ]),
    getCurrentIndex(type) {
      const index = this.notify.findIndex((item) => item.type === type);
      return index;
    },
    changeEnable(type, val, $e) {
      $e.stopPropagation();
      this.notify.forEach((item) => {
        if (item.type === type) item.enabled = val;
      });
      this.syncNotify();
    },
    togglePanel(key) {
      let arr = [];
      this.isActive[key] = !this.isActive[key];
      for (var item in this.isActive) {
        if (this.isActive[`${item}`]) arr.push(item);
      }
      this.activeKey = arr;
    },
    initNotify() {
      if (
        this.task.stepInfo &&
        this.task.stepInfo.taskTemplateDetail &&
        this.task.stepInfo.taskTemplateDetail?.notify && this.task.stepInfo.taskTemplateDetail?.notify.length
      ) {
          this.notify = _.cloneDeep(
            this.task.stepInfo.taskTemplateDetail?.notify
          );
      } else {
        this.notify = [
          {
            enabled: true,
            type: "email",
            events: ['workflow_fail'],
            contents: [],
            object: {
              email_address: "",
              phone_num: "",
              dingtalk_privatekey: "",
              dingtalk_webhook: "",
            },
          },
          {
            enabled: false,
            type: "dingtalk",
            events: ['workflow_fail'],
            contents: [],
            object: {
              email_address: "",
              phone_num: "",
              dingtalk_privatekey: "",
              dingtalk_webhook: "",
              dingtalk_checked: false
            },
          },
          {
            enabled: false,
            type: "sms",
            events: ['workflow_fail'],
            contents: [],
            object: {
              email_address: "",
              phone_num: "",
              dingtalk_privatekey: "",
              dingtalk_webhook: "",
            },
          },
        ];
        this.syncNotify();
      }
    },
    getEventType(val) {
      this.notify.forEach((item) => {
        if (item.type === "email") item.events = val;
      });
      this.syncNotify();
    },
    getNotify (type, val) {
      this.notify.forEach((item) => {
        if (item.type === type) item = {...val}
      });
      this.syncNotify();
    },
    syncNotify() {
      const taskInfo = _.cloneDeep(this.task.stepInfo);
      if (this.task.stepInfo && this.task.stepInfo.taskTemplateDetail) {
        taskInfo['taskTemplateDetail']['notify'] = this.notify
         this.setStepInfo(taskInfo);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.notice {
  height: 785px;
  margin-right: 24px;
  max-height: 785px;
  overflow-y: auto;
  .title {
    font-size: 17px;
    color: #1d202d;
    font-weight: 550;
    .tips {
      font-size: 14px;
      color: #1d202d;
      letter-spacing: 0;
      font-weight: 400;
    }
  }
  .header-icon {
    font-size: 17px;
    color: #1d202d;
    position: relative;
    top: 1px;
  }
  /deep/ .ant-collapse-borderless {
    background: #fff;
    .ant-collapse-item {
      border-bottom: none;
    }
  }
}
</style>
