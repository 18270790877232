<!--
 * @Author: mjzhu
 * @describe: step2-主机环境校验 
 * @Date: 2022-06-13 16:35:02
 * @LastEditTime: 2023-11-28 15:46:42
 * @FilePath: \awx-ui\src\components\workflow-design\components\app.vue
-->
<template>
  <div class="right-app steps">
    <a-spin :spinning="spinning" style="width: 100%;">
      <a-input ref="userNameInput" v-model="templateName"  @pressEnter="getTemplates(0)" placeholder="搜索应用名称找到更多应用">
      <svg-icon icon-class="search" class="template-search" slot="prefix" ></svg-icon>
      </a-input>
      <div class="rf">
        <a-tabs v-model="templateTab" @change="callback" :class="[templateTab === 'all' && 'all-tab']" :tabBarStyle="{borderBottom: '1px solid #cfd5de'}">
          <a-tab-pane v-for="(item) in templateCate" :tab="item.name" :key="item.catagory"></a-tab-pane>
        </a-tabs>
        <a-dropdown v-if="allList.length > 6" :trigger="['click']">
          <a class="ant-dropdown-link" @click="e => e.preventDefault()">
            <span class="tabs-more">更多</span>
            <a-icon class="tabs-icon" type="down" />
          </a>
          <a-menu slot="overlay" v-model="templateTabArr">
            <a-menu-item v-for="(item) in moreList" :key="item.id">
              <div @click="changeMore(item)">{{ item.name }}</div>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
        <ChooseTemplete :systemTemplateList="systemTemplateList" :customTemplateList="customTemplateList" @seletCate="seletCate" @selectedTemplate="selectedTemplate" />
      </div>
    </a-spin>
  </div>
</template>
<script>
import ChooseTemplete from "./selectApp";
export default {
  props: {
    steps1Data: Object,
  },
  data() {
    return {
      current: 1,
      total: 0,
      templateTab: "all",
      templateName: "",
      timer: null,
      spinning: false,
      systemLength: 1,
      customLength: 0,
      cateType: "",
      templateCate: [],
      templateTabArr: [],
      selectedTemplateId: "",
      customTemplateList: [],
      systemTemplateList: [
      ],
      moreList: [],
      allList: []
    };
  },
  computed: {},
  components: { ChooseTemplete },
  methods: {
    changeMore (item) {
      this.templateTab = ''
      this.templateTabArr = []
      this.templateTabArr.push(item.catagory)
      this.getTemplateList(item.catagory)
    },
    callback(key) {
      this.templateTab = key;
      this.getTemplateList(key);
    },
    getCatagories() {
      this.$axiosGet(global.API.getAppCatagories, {
        page: 1,
        pageSize: 1000,
      }).then((res) => {
        this.loading = false;
        if ([200, 201, 204, 202].includes(res.status)) {
          this.templateCate = [
            { catagory: "all", name: "全部" },
          ];
          res.data.results.map((item) => {
            this.templateCate.push(item);
          });
          let arr = _.cloneDeep(this.templateCate);
          this.allList = _.cloneDeep(this.templateCate);
          this.templateCate = this.templateCate.splice(0, 6);
          this.moreList = arr.splice(6, arr.length - 1);
        }
      });
    },
    changePage(page) {
      this.current = page;
      this.getTemplates(0);
    },
    // 查询模板
    onSearch(val) {
      this.keyword = val;
      this.getTemplates(0);
    },
    seletCate(type) {
      this.cateType = type;
    },
    selectedTemplate(id) {
      this.selectedTemplateId = id;
      this.$emit('selectedTemplate')
    },
    getTemplateList(key) {
      this.spinning = true;
      const params = {
        page_size: 1000,
        page: this.current,
        keyword: this.templateName || "",
      };
      if (key !== 'all') params.catagory = key;
      this.$axiosGet(global.API.getApp, params).then((res) => {
        this.spinning = false;
        if ([200, 201, 204, 202].includes(res.status)) {
          this.systemTemplateList = res.data.results;
          this.systemLength = this.systemTemplateList.length;
          this.total = res.data.total + 1;
        }
      });
    },
    //表搜索
    getTemplates(type) {
      this.spinning = true;
      const params = {
        page_size: 1000,
        page: this.current,
        keyword: this.templateName || "",
      };
      this.$axiosGet(global.API.getApp, params).then((res) => {
        this.spinning = false;
        if ([200, 201, 204, 202].includes(res.status)) {
          this.systemTemplateList =res.data.results;
          this.systemLength = this.systemTemplateList.length;
          this.total = res.data.total + 1;
        }
      });
    },
  },
  mounted() {
    this.getCatagories();
    this.getTemplates(0);
  },
  beforeDestroy() {
    this.$EventBus.$off("getTemplates");
  },
};
</script>
<style lang="less" scoped>
.right-app {
  display: flex;
  justify-content: center;
  padding: 29px 24px 0 0;
  .flex-warp {
    display: flex;
  }
  .rf {
    position: relative;
    max-height: calc(100vh - 220px); 
    height: calc(100vh - 220px);
    overflow-y: auto;
    padding: 6px 0 0 0;
  }
  .pagination-footer {
    height: 72px;
    border-top: 1px solid #dfe8f3;
    text-align: center;
    padding: 20px 0 0 0;
  }
  .template-search {
    // color: @primary-color;
    font-size: 16px;
  }
  /deep/ .ant-tabs-tab {
    font-size: 14px;
    color: #34343c;
  }
  /deep/ .ant-tabs-nav .ant-tabs-tab-active {
    color: #3974f4;
  }
  /deep/ .ant-tabs-ink-bar {
    height: 3px;
  }
  .all-tab {
    /deep/ .ant-tabs-ink-bar {
      width: 28px!important;
    }
  }
  .tabs-more {
    font-size: 14px;
    color: #34343C;
    margin-right: 6px;
  }
  .tabs-icon {
    font-size: 14px!important;
    color: #34343C;
  }
  .ant-dropdown-link {
    position: absolute;
    right: 0;
    top: 18px;
    z-index: 100000;
  }
}
</style>