<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2023-10-26 11:11:25
 * @LastEditTime: 2023-11-03 11:28:39
 * @FilePath: \awx-ui\src\components\workflow-design\components\node\components\addBracnch.vue
-->
<template>
  <div class="add-branch">
    <!-- <div class="name">IF Step</div> -->
    <a-form-model ref="AddBranchRef" :model="form" :rule="rule" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item label="分支名称">
        <a-input v-model="form.name" onkeyup="this.value=this.value.replace(/[^\w_]/g,'');" />
      </a-form-model-item>
    </a-form-model>
    <div class="footer-btn">
      <a-button class="mgr10" @click="handleCancel">取消</a-button>
      <a-button class="" type="primary" :loading="confirmLoading" @click="confirmItem">{{ '确定' }}</a-button>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  props: {
    callBack: Function,
    name: String,
    conditions: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
      confirmLoading: false,
      form: {
        name: "",
      },
      rule: {
        name: [
          {
            required: true,
            message: "请输入分支名称",
            trigger: ["change", "blur"],
          },
        ],
      }
    };
  },
  computed: {
    ...mapState({
      task: (state) => state.task, //深拷贝的意义在于watch里面可以在Watch里面监听他的newval和oldVal的变化
    }),
  },
  mounted() {
    if (this.name) {
      this.form.name = this.name
    }
  },
  methods: {
    handleCancel () {
      this.$destroyAll()
    },  
    confirmItem () {
      this.$refs.AddBranchRef.validate(async (valid) => {
        if (valid) {
          if (this.name) delete this.conditions[`${this.name}`]
          if (this.form.name in this.conditions) return this.$message.warning('当前分支已存在')
          this.confirmLoading = true
          this.callBack(this.form, this.name)
          this.handleCancel()
        }
      });
    }
  },
};
</script>
<style lang="less" scoped>
.add-branch {
  padding: 10px 24px;
  .name {
    font-size: 18px;
    font-weight: 550;
  }
  .footer-btn {
    display: flex;
    justify-content: flex-end;
  }
}
</style>